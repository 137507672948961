<template>
  <v-container grid-list-md pa-0 relative class="page-layout">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card>
            <v-card-title class="title">
              <v-icon>line_style</v-icon> &nbsp; Sales Items
              <v-spacer></v-spacer>
            </v-card-title>

            <v-card-title>
              <v-flex xs3>
                <v-autocomplete
                  v-model="form.ledger_head_id"
                  :items="ledgers"
                  :search-input.sync="searchL"
                  hide-no-data
                  item-text="name"
                  item-value="id"
                  label="Account Ledger(Debitor)"
                  placeholder="Start typing to Search"
                  class="pa-1"
                  persistent-hint
                  :hint="ledger_opening"
                  :height="25"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs3>
                <v-text-field
                  label="Sales Bill Date"
                  v-model="form.sale_bill_date"
                  name="sale_bill_date"
                  prepend-icon="event"
                  class="pa-1"
                  mask="####-##-##"
                  placeholder="YYYY-MM-DD"
                  :error-messages="form.errors.get('sale_bill_date')"
                  :height="25"
                  :readonly="form.loading"
                />
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  label="Sales Bill No"
                  v-model="form.sale_bill_no"
                  name="sale_bill_no"
                  :error-messages="form.errors.get('sale_bill_no')"
                  :height="25"
                  class="pa-1"
                  :readonly="form.loading"
                />
              </v-flex>

              <v-flex xs3>
                <v-select
                  :readonly="form.loading"
                  v-model="form.bill_type"
                  :items="billTypes"
                  class="pa-1"
                  label="Select Bill Type"
                  name="bill_type"
                  :height="25"
                />
              </v-flex>
              <v-flex xs3>
                <v-select
                  :readonly="form.loading"
                  v-model="form.voucher_type_id"
                  :items="voucherTypes"
                  class="pa-1"
                  label="Select Voucher Type"
                  name="voucher_type_id"
                  :height="25"
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <div class="itemWrap">
            <v-layout row wrap>
              <v-flex xs3>
                <v-autocomplete
                  v-model="addItemData.item_id"
                  :items="items"
                  :search-input.sync="searchI"
                  hide-no-data
                  item-text="name"
                  item-value="id"
                  label="Item"
                  placeholder="Start typing to Search"
                  class="pa-1"
                  :height="25"
                  persistent-hint
                  :hint="addItemData.unit_name ? addItemData.unit_name : ''"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs2>
                <v-select
                  :readonly="form.loading"
                  v-model="addItemData.unit_id"
                  :items="units"
                  class="pa-1"
                  label="Unit"
                  name="unit_id"
                  :height="25"
                  :disabled="false"
                />
              </v-flex>
              <v-flex xs2>
                <v-select
                  :readonly="form.loading"
                  v-model="addItemData.warehouse_id"
                  :items="warehouses"
                  class="pa-1"
                  label="Select Ware House"
                  name="warehouse_id"
                  :height="25"
                  :disabled="!addItemData.item_id"
                />
              </v-flex>

              <v-flex xs1>
                <v-text-field
                  v-model="addItemData.qty"
                  name="qty"
                  label="Quantity"
                  @keyup="evaluateQantity"
                  class="pa-1"
                  type="number"
                  :height="25"
                  :disabled="
                    !addItemData.warehouse_id && parseFloat(stock_qty) === 0
                  "
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs1>
                <v-text-field
                  v-model="addItemData.rate"
                  name="rate"
                  label="Rate"
                  class="pa-1"
                  :error-messages="form.errors.get('rate')"
                  :height="25"
                  :disabled="!addItemData.warehouse_id && stock_qty === 0"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex xs1>
                <v-text-field
                  v-model="addItemData.amount"
                  name="amount"
                  label="Amount"
                  class="pa-1"
                  :error-messages="form.errors.get('amount')"
                  :height="25"
                  :disabled="!addItemData.warehouse_id && stock_qty === 0"
                  autocomplete="off"
                />
              </v-flex>
              <v-flex>
                <v-btn
                  v-if="update"
                  style="margin-top: 10px"
                  color="success"
                  outline
                  flat
                  @click="updateItem(addItemData)"
                  :disabled="
                    !(
                      addItemData.warehouse_id &&
                      addItemData.rate &&
                      addItemData.qty &&
                      addItemData.amount
                    )
                  "
                >
                  Update
                </v-btn>
                <v-btn
                  v-else
                  style="margin-top: 10px"
                  color="success"
                  outline
                  flat
                  @click="addItem(addItemData)"
                  :disabled="
                    !(
                      addItemData.warehouse_id &&
                      addItemData.rate &&
                      addItemData.qty &&
                      addItemData.amount
                    )
                  "
                >
                  OK
                </v-btn>
              </v-flex>
              <div v-if="addItemData.item_id">
                <span class="span-title">Min. Sales Rate:</span>
                <span class="span-qty">{{ addItemData.min_sales_rate }}</span>
                <span class="span-title">Mrp Sales:</span>
                <span class="span-qty">{{ addItemData.mrp_sales }}</span>
                <span class="span-title">Sales Rate:</span>
                <span class="span-qty">{{ addItemData.sales_rate }}</span>
              </div>
              <div v-if="addItemData.warehouse_id">
                <span class="span-title">Stock Quantity:</span>
                <span class="span-qty">{{ stock_qty }}</span>
              </div>
            </v-layout>
          </div>
          <v-data-table
            :headers="headers"
            :items="form.item_data"
            hide-actions
            class="tb"
          >
            <template slot="items" slot-scope="props">
              <td>{{ ++props.index }}</td>
              <td class="text-xs-left">
                {{ props.item.item_name }}
              </td>
              <td class="text-xs-left">
                <v-chip
                  v-if="props.item.is_taxable === 1"
                  color="green"
                  text-color="white"
                  class="chipH"
                >
                  Yes
                </v-chip>
                <v-chip v-else color="red" class="chipH" text-color="white"
                  >No</v-chip
                >
              </td>
              <td class="text-xs-left">
                {{ props.item.warehouse_name }}
              </td>
              <td class="text-xs-left">{{ props.item.qty }}</td>
              <td class="text-xs-left">{{ props.item.rate }}</td>
              <td class="text-xs-left">{{ props.item.unit_name }}</td>
              <td class="text-xs-left">{{ props.item.amount }}</td>
              <td class="text-xs-right">
                <v-icon
                  small
                  @click="editItem(props.item, props.index)"
                  style="margin-right: 2px; background: blue; color: white"
                >
                  edit
                </v-icon>

                <v-icon
                  small
                  @click="removeItem(props.item)"
                  style="background: red; color: white"
                  >clear
                </v-icon>
              </td>
            </template>
            <template v-slot:footer v-if="form.item_data.length">
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">Sub Total:</td>
                <td :colspan="2">{{ sub_total }}</td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">Dis %</td>
                <td :colspan="2" class="text-xs-right">
                  <input
                    type="number"
                    @keyup="discountPercentage"
                    class="discountText"
                    v-model="form.discount_in_percentage"
                  />
                </td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right" width="20px">
                  Dis Amount:
                </td>
                <td :colspan="2">
                  <input
                    type="number"
                    @keyup="discountAmount"
                    class="discountText"
                    v-model="form.discount_in_amount"
                  />
                </td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">Taxable Amount:</td>
                <td :colspan="2">{{ taxable_amount_after_discount }}</td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">Non Taxable Amount:</td>
                <td :colspan="2">{{ non_taxable_amount_after_discount }}</td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">VAT 13%:</td>
                <td :colspan="2">{{ tax_amount }}</td>
              </tr>
              <tr class="footerRow">
                <td :colspan="7" class="text-xs-right">Total:</td>
                <td :colspan="2">
                  {{ total_amount }}
                  <!--taxable: {{taxable_item_count}}-->
                  <!--no taxable: {{non_taxable_item_count}}-->
                </td>
              </tr>
              <tr class="footerRow">
                <td>Narration:</td>
                <td :colspan="8" width="100%">
                  <textarea
                    v-model="form.narration"
                    :rows="3"
                    class="footerTextarea"
                    :error-messages="form.errors.get('narration')"
                  ></textarea>
                </td>
              </tr>
              <tr class="footerRowNarration">
                <td :colspan="headers.length" class="text-xs-right">
                  <v-btn
                    color="warning"
                    outline
                    flat
                    @click="(form.dialog = false), form.reset()"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="success" outline flat @click="save">Save</v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import { mapState } from "vuex";
import Form from "@/library/Form";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        bill_type: "",
        discount_in_percentage: 0,
        discount_in_amount: 0,

        sale_bill_no: "",
        sale_bill_date: "",
        ledger_head_id: "",
        voucher_type_id: "",

        sub_total: 0,
        taxable_amount_after_discount: 0,
        non_taxable_amount_after_discount: 0,
        total_amount: 0,
        vat_amount: 0,
        narration: "",

        item_data: [
          // {
          //     warehouse_id: '',
          //     is_taxable: '',
          //     item_id: '',
          //     qty: '',
          //     rate: '',
          //     amount: '',
          //     item_name: '',
          //     warehouse_name: '',
          // }
        ],
        notify: {
          min_qty: "",
          max_qty: "",
          reorder_level: "",
        },
      },
      "/api/account/inventory/sale"
    ),

    stock_qty: 0,
    searchI: null,
    searchL: null,
    searchW: null,
    wareHouseSearchData: [],
    itemSearchData: [],
    units: [],
    ledgerSearchData: [],
    voucherTypes: [],
    addItemData: {
      warehouse_id: "",
      is_taxable: "",
      item_id: "",
      qty: "",
      rate: "",
      amount: "",
      item_name: "",
      warehouse_name: "",
      min_sales_rate: "",
      mrp_sales: "",
      sales_rate: "",
      code: "",
    },
    sub_total: 0,
    taxable_amount: 0,
    non_taxable_amount: 0,
    tax_amount: 0,
    total_amount: 0,
    taxable_item_count: 0,
    non_taxable_item_count: 0,
    individual_discount: 0,
    taxable_amount_after_discount: 0,
    non_taxable_amount_after_discount: 0,
    ledger_opening: "",

    warehouses: [],

    editDataResetFlag: true,
    update: false,
    headers: [
      { text: "#", align: "left", sortable: false, value: "id", width: "20px" },
      { text: "Item", value: "item", align: "left" },
      { text: "Vatable", value: "item", align: "left", width: "20px" },
      { text: "Ware House", value: "warehouse", align: "left" },
      { text: "Qty", value: "qry", align: "left", sortable: false },
      { text: "Rate", value: "rate", align: "left", sortable: false },
      { text: "Unit", value: "unit", sortable: false, align: "left" },
      { text: "Amount", value: "amount", sortable: false, align: "left" },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "right",
        width: 120,
      },
    ],
    billTypes: [
      { value: "cash", text: "Cash" },
      { value: "credit", text: "Credit" },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
    items() {
      return this.itemSearchData.map((entry) => {
        return { name: entry.name, id: entry.id, data: entry };
      });
    },
    ledgers() {
      return this.ledgerSearchData.map((entry) => {
        return { name: entry.name, id: entry.id, data: entry };
      });
    },
  },
  mounted() {
    this.getVoucherTypes();
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    "form.item_id": function (v) {
      let data = this.items.find((res) => res.id === v);
    },
    searchI(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get("api/account/inventory/item?search=" + val)
          .then(({ data }) => {
            this.itemSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id, data: res };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchL(val) {
      if (!val) return;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get("api/ledger-head?search=" + val + "&type=debtors")
          .then(({ data }) => {
            this.ledgerSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id, data: res };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },

    "addItemData.item_id": function (v) {
      if (v) {
        let itemData = this.findItem(v);
        this.addItemData.is_taxable = itemData.data.is_taxable;
        if (!this.update) {
          this.units = [
            { value: itemData.data.unit_id, text: itemData.data.unit_name },
          ];

          this.addItemData.unit_id = itemData.data.unit_id;
          this.addItemData.unit_name = itemData.data.unit_name;
          this.addItemData.item_id = itemData.data.id;
          this.addItemData.item_name = itemData.data.name;

          this.addItemData.min_sales_rate = itemData.data.min_sales_rate;
          this.addItemData.mrp_sales = itemData.data.mrp_sales;
          this.addItemData.code = itemData.data.code;
          this.addItemData.sales_rate = itemData.data.sales_rate;
        }
        if (this.editDataResetFlag) {
          this.addItemData.warehouse_id = "";
          this.editDataResetFlag = true;
        }
        if (!this.editDataResetFlag && this.update) {
          this.editDataResetFlag = true;
        }
        this.$rest
          .get("api/account/inventory/stock/find-warehouse/" + v)
          .then((res) => {
            this.warehouses = res.data.data.map((w) => {
              return { text: w.name, value: w.warehouse_id };
            });
          });
        console.log(this.warehouses, "testing......");
        // this.warehouses = itemData.data.openings.map(res => {
        //     return {
        //         text: res.warehouse_name,
        //         value: res.warehouse_id,
        //     }
        // });
        //
        // this.addItemData.qty = 0;
        this.stock_qty = 0;
      }
    },
    "addItemData.warehouse_id": function (v) {
      if (v) {
        let wData = this.findWareHouse(v);
        this.addItemData.warehouse_name = wData ? wData.text : "";
      }
      console.log(this.addItemData);

      if (this.addItemData.warehouse_id) {
        this.$rest
          .get(
            "/api/account/inventory/stock/qty?itemId=" +
              this.addItemData.item_id +
              "&warehouseId=" +
              this.addItemData.warehouse_id
          )
          .then((res) => {
            this.stock_qty = res.data;
          });
      }
      if (!this.editDataResetFlag && this.update) {
        this.addItemData.qty = 0;
        this.addItemData.rate = 0;
      }
    },
    "addItemData.rate": function (v) {
      if (v && this.addItemData.qty) {
        this.addItemData.amount =
          parseFloat(v) * parseFloat(this.addItemData.qty);
      } else {
        this.addItemData.amount = 0;
      }
    },
    // 'addItemData.qty': function (v) {
    //     // console.log(v);
    //     if (parseFloat(v) > this.stock_qty) {
    //         this.addItemData.qty = this.stock_qty;
    //     }
    //     if (v && this.addItemData.rate) {
    //         this.addItemData.amount = parseFloat(v) * parseFloat(this.addItemData.rate);
    //     } else {
    //         this.addItemData.amount = 0;
    //     }
    // },
    "addItemData.amount": function (v) {
      if (v && this.addItemData.qty) {
        this.addItemData.rate =
          parseFloat(v) / parseFloat(this.addItemData.qty);
      }
    },
    "form.ledger_head_id": function (v) {
      this.$rest.get("/api/ledger-head/opening/" + v).then((res) => {
        if (res.data.length) {
          if (res.data > 0)
            this.ledger_opening =
              "openinging Balance(Dr):" + Math.abs(res.data);
          else
            this.ledger_opening =
              "openinging Balance(Cr):" + Math.abs(res.data);
        } else this.ledger_opening = "openinging Balance: 0";
      });
    },
  },

  methods: {
    editData(data) {
      this.categorySearchData = [];
      this.categorySearchData.push({
        name: data.parent_name,
        id: data.parent_id,
      });
    },
    evaluateQantity(e) {
      let v = e.target.value;
      if (parseFloat(v) > parseFloat(this.stock_qty)) {
        this.addItemData.qty = this.stock_qty.toString();
      } else {
        this.addItemData.qty = v;
      }

      if (v && this.addItemData.rate) {
        this.addItemData.amount =
          parseFloat(this.addItemData.qty) * parseFloat(this.addItemData.rate);
      } else {
        this.addItemData.amount = 0;
      }
    },

    save() {
      this.form.sale_bill_date = this.form.sale_bill_date.dateForm();
      this.form.total_amount = this.total_amount;
      this.form.vat_amount = this.tax_amount;
      this.form.sub_total = this.sub_total;
      this.form.taxable_amount_after_discount =
        this.taxable_amount_after_discount;
      this.form.non_taxable_amount_after_discount =
        this.non_taxable_amount_after_discount;
      this.form
        .store()
        .then(() => {
          this.form.dialog = false;
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
        })
        .catch((e) => {
          this.form.dialog = false;
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    searchItem: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
    addItem(item) {
      // console.log(item);
      if (item.warehouse_id && item.qty && item.rate) {
        // item.warehouse_name = this.findWareHouse(item.warehouse_id).name;
        item.item_name = this.findItem(item.item_id).name;
        // item.unit_name = this.findUnit(item.unit_id).name;
        this.form.item_data.push(item);
        this.update = false;
        this.addItemdataReset();
        this.calculation();
      }
    },
    removeItem: function (item) {
      var index = this.form.item_data.indexOf(item);
      this.form.item_data.splice(index, 1);
    },
    editItem(item, index) {
      this.editDataResetFlag = false;

      // this.wareHouseSearchData = [{name: item.warehouse_name, id: item.warehouse_id}];
      this.update = true;
      this.editIndex = index - 1;
      this.addItemData.qty = item.qty;
      this.addItemData.rate = item.rate;
      this.addItemData.is_taxable = item.is_taxable;
      this.addItemData.amount = item.amount;
      this.addItemData.warehouse_id = item.warehouse_id;
      this.addItemData.warehouse_name = item.warehouse_name;

      this.addItemData.unit_id = item.unit_id;
      this.addItemData.item_id = item.item_id;

      this.addItemData.min_sales_rate = item.min_sales_rate;
      this.addItemData.mrp_sales = item.mrp_sales;
      this.addItemData.code = item.code;
      this.addItemData.sales_rate = item.sales_rate;

      this.searchW = item.warehouse_name;
      this.wareHouseSearchData.push({
        name: item.warehouse_name,
        id: item.warehouse_id,
      });
      // this.units = [{text: item.unit_name, value: item.unit_id}];
      this.units = [{ value: item.unit_id, text: item.unit_name }];

      this.itemSearchData.push({ name: item.item_name, id: item.item_id });
    },
    updateItem() {
      this.fillAddItemData(this.editIndex, this.addItemData);
      this.update = false;
      this.addItemdataReset();
      this.calculation();
    },
    findWareHouse(warehouse_id) {
      console.log(this.warehouses);
      let data = this.warehouses.filter((res) => {
        if (res.value === warehouse_id) return res;
      });
      return data[0];
      // return this.warehouses.filter(res => res.warehouse_id === warehouse_id);
    },
    findUnit(unit_id) {
      return this.units.find((res) => res.id === unit_id);
    },
    findItem(item_id) {
      let data = this.itemSearchData.filter((res) => {
        if (res.id === item_id) return res;
      });
      return data[0];
    },
    getVoucherTypes() {
      this.$rest.get("/api/journal-type?prType=purchase").then((res) => {
        this.voucherTypes = res.data.data.map((res) => {
          return { value: res.id, text: res.name };
        });
      });
    },

    discountPercentage() {
      if (this.form.discount_in_percentage > 100)
        this.form.discount_in_percentage = 100;
      this.form.discount_in_amount = (
        (this.sub_total * this.form.discount_in_percentage) /
        100
      ).toFixed(2);
      this.calculation();
    },
    discountAmount() {
      if (this.sub_total < this.form.discount_in_amount)
        this.form.discount_in_amount = this.sub_total;
      this.form.discount_in_percentage = (
        (this.form.discount_in_amount / this.sub_total) *
        100
      ).toFixed(2);
      this.calculation();
    },
    calculation() {
      this.sub_total = 0;
      this.taxable_amount = 0;
      this.non_taxable_amount = 0;
      this.taxable_item_count = 0;
      this.non_taxable_item_count = 0;

      this.form.item_data.map((res) => {
        this.sub_total += parseFloat(res.amount);
        if (res.is_taxable) {
          this.taxable_item_count++;
          this.taxable_amount += parseFloat(res.amount);
        }
        if (!res.is_taxable) {
          this.non_taxable_amount += parseFloat(res.amount);
          this.non_taxable_item_count++;
        }
      });

      let individual_discount_amount = (
        parseFloat(this.form.discount_in_amount) / this.form.item_data.length
      ).toFixed(2);
      let discount_for_taxable_amount = (
        this.taxable_item_count * individual_discount_amount
      ).toFixed(2);
      let discount_for_non_taxable_amount = (
        this.non_taxable_item_count * individual_discount_amount
      ).toFixed(2);

      this.taxable_amount_after_discount = (
        parseFloat(this.taxable_amount) -
        parseFloat(discount_for_taxable_amount)
      ).toFixed(2);
      if (["", "NaN", null].includes(this.taxable_amount_after_discount)) {
        this.taxable_amount_after_discount = 0;
      }

      this.non_taxable_amount_after_discount = (
        parseFloat(this.non_taxable_amount) - discount_for_non_taxable_amount
      ).toFixed(2);
      if (["", "NaN", null].includes(this.non_taxable_amount_after_discount)) {
        this.non_taxable_amount_after_discount = 0;
      }

      this.tax_amount = (
        parseFloat(this.taxable_amount_after_discount) * 0.13
      ).toFixed(2);
      this.total_amount = (
        parseFloat(this.taxable_amount_after_discount) +
        parseFloat(this.non_taxable_amount_after_discount) +
        parseFloat(this.tax_amount)
      ).toFixed(2);

      if (["", NaN, null].includes(this.tax_amount)) this.tax_amount = 0;
      if (["", NaN, null].includes(this.total_amount)) this.total_amount = 0;
    },
    addItemdataReset() {
      this.addItemData = {
        warehouse_id: "",
        is_taxable: 0,
        item_id: "",
        qty: 0,
        rate: 0,
        amount: 0,
        item_name: "",
        warehouse_name: "",
      };
    },
    fillAddItemData(index, item) {
      // this.form.item_data[index]
      this.form.item_data[index].qty = item.qty;
      this.form.item_data[index].rate = item.rate;
      this.form.item_data[index].amount = item.amount;
      this.form.item_data[index].warehouse_id = item.warehouse_id;
      this.form.item_data[index].warehouse_name = item.warehouse_name;
      this.form.item_data[index].is_taxable = item.is_taxable;

      this.form.item_data[index].unit_id = item.unit_id;
      this.form.item_data[index].item_id = item.item_id;
    },
  },
};
</script>
<style lang="scss">
.itemWrap {
  margin: 25px;
  border: 1px solid #ccc;
  padding: 10px;
}

.discountText {
  height: 25px;
  border: 1px solid #bbb;
  text-align: left;
  /*width: 75%;*/
  width: 100%;
}

/*.discountPer {*/
/*height: 25px;*/
/*border: 1px solid #bbb;*/
/*width: 105%;*/
/*!*width: 60px;*!*/
/*!* padding-bottom: 0px; *!*/
/*text-align: center;*/
/*!*width: 109px;*!*/
/*!*margin-right: 40px;*!*/
/*}*/

.dis_form_container {
  /*margin-top: 20px;*/
  /*background-color: red;*/
  display: flex;

  .dis_form_label {
    flex: 2;
    padding: 15px;
    /*border-right: 1px dashed #eee;*/
    /*background-color: green;*/
  }

  .dis_form_input {
    flex: 1;
    padding: 5px;
    /*background-color: orange;*/
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.footerRow {
  height: 25px !important;
  border: none !important;
}

.footerRowNarration {
  border: none !important;
}

.footerTextarea {
  border: 1px solid #bbb;
  text-align: left;
  /*width: 75%;*/
  width: 100%;
}

.chipH {
  height: 20px;
}

.tb tr:last-child {
  border-bottom: 1px solid black;
}

.span-title {
  color: red;
  padding-top: 15px;
  padding-right: 5px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.span-qty {
  color: green;
}
</style>

